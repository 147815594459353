const {REACT_APP_API_BASE_URL, REACT_APP_CLIENT_ACCESS_TOKEN} = process.env;

let userAccessToken = localStorage.getItem('userAccessToken') || '';

const _encodePair = (pair) => {
    const [k, v] = pair;
    if (Array.isArray(v)) {
        return v.map(vv => encodeURIComponent(k) + '[]=' + encodeURIComponent(vv)).join('&')
    }
    return encodeURIComponent(k) + '=' + encodeURIComponent(v);
};

const _makeQueryString = (query = {}) => {
    let str = Object.entries(query).map(_encodePair).join('&'); // stupid
    if (str) {
        str = '?'+str;
    }
    return str;
};

const _fetch = async (url, options) => {
    const res = await fetch(url, options);
    const ret = {
        is2xx: res.status>=200 && res.status<=299,
        is3xx: res.status>=300 && res.status<=399,
        is4xx: res.status>=400 && res.status<=499,
        is5xx: res.status>=500 && res.status<=599,
        url: res.url,
        status: res.status,
        headers: res.headers.map,
        body: await res.text(),
    };

    try {
        ret.body = JSON.parse(ret.body);
    } catch (err) {
        if (err instanceof SyntaxError) {
            console.log('Response not JSON');
        }
    }

    return ret;
};

const _get = (endpoint, query={}) => {
    const url = REACT_APP_API_BASE_URL + endpoint + _makeQueryString(query);
    const options = {
        method: 'GET',
        headers: {
            'x-mc-client-access-token': REACT_APP_CLIENT_ACCESS_TOKEN,
            'x-mc-user-access-token': userAccessToken,
        },
    };
    return _fetch(url, options);
};

const _post = async (endpoint, body) => {
    const url = REACT_APP_API_BASE_URL + endpoint;
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'x-mc-client-access-token': REACT_APP_CLIENT_ACCESS_TOKEN,
            'x-mc-user-access-token': userAccessToken,
        },
        body: JSON.stringify(body),
    };
    return _fetch(url, options);
};

const _withRefresh = async (fn) => {
    const ret = await fn();
    if (ret.status === 401 && ret.body.message === 'User token is expired') {
        const refreshRet = await mcRefreshTokens();
        if (refreshRet) {
            const ret2 = await fn();
            return ret2;
        }

        await mcLogOut();
    }
    return ret;
};

export const mcSubmitContactUsForm = async (name, email_address, message) => {
    const body = {name, email_address, message};
    return _post('/ContactUs', body);
};

export const mcGetFaqs = async () => {
    return _get('/GetFAQs')
};

export const mcGetTestimonials = async () => {
    return _get('/GetTestimonials');
};

export const mcIsLoggedIn = async () => {
    const ret = await _get('/CheckAuth');
    return ret;
};

export const mcLogIn = async (email_address, password) => {
    const body = {email_address, password};
    const ret = await _post('/AuthenticateUser', body);
    if (ret.is2xx) {
        userAccessToken = ret.body.access_token;
        localStorage.setItem('userAccessToken', ret.body.access_token);
        localStorage.setItem('userRefreshToken', ret.body.refresh_token);
    }
    return ret;
};

export const mcRefreshTokens = async () => {
    const refresh_token = localStorage.getItem('userRefreshToken') || '';
    const body = {refresh_token};
    const ret = await _post('/RefreshUserTokens', body);
    if (ret.is2xx) {
        localStorage.setItem('userAccessToken', ret.body.access_token);
        localStorage.setItem('userRefreshToken', ret.body.refresh_token);
        return true;
    }

    mcLogOut();
    return false;
};

export const mcLogOut = () => {
    userAccessToken = '';
    localStorage.removeItem('userAccessToken');
    localStorage.removeItem('userRefreshToken');
};

export const mcResetPassword = async (email_address) => {
    const body = {email_address};
    return _post('/ForgotPassword', body);
};

export const mcGetMyLocations = async () => {
    return _withRefresh(() => _get('/GetMyLocations'));
};

export const mcGetMarketingCampaigns = async () => {
    return _get('/GetMarketingCampaigns');
};

export const mcGetProducts = async () => {
    return _get('/GetProducts');
};

export const mcSubmitOrder = async (patient, campaign_ids, product_ids) => {
    const body = {patient, campaign_ids, product_ids};
    return _withRefresh(() => _post('/SubmitOrder', body));
};
