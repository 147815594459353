import { useState, useEffect } from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Dashboard from "./pages/Dashboard";
import OrderForm from "./pages/OrderForm";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ForgotPassword from "./pages/ForgotPassword";
import Error from "./pages/Error";
import { mcIsLoggedIn } from './apiClient';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(function() {
        async function fetchData() {
            const authRes = await mcIsLoggedIn();
            setIsLoggedIn(!!authRes.body.user_active);
        }

        fetchData();
    }, []);

    const path = window.location.pathname;

    const navigate = (val) => {
        window.location.href = val;
    };

    const getPageToRender = () => {
        if (path === '/') {
            return <Home navigate={navigate} />
        }

        if (path === '/contact-us') {
            return  <ContactUs navigate={navigate} />
        }

        if (path === '/dashboard') {
            return isLoggedIn
                ? <Dashboard navigate={navigate} />
                : <Login navigate={navigate} />;
        }

        if (path === '/order-form') {
            return isLoggedIn
                ? <OrderForm navigate={navigate} patient={{}} />
                : <Login navigate={navigate} />
        }

        if (path === '/login') {
            return isLoggedIn
                ? <Error navigate={navigate} message='Already Logged In' />
                : <Login navigate={navigate} />;
        }

        if (path === '/logout') {
            return <Logout navigate={navigate} />;
        }

        if (path === '/forgot-password') {
            return isLoggedIn
                ? <Error navigate={navigate} message='Already Logged In' />
                : <ForgotPassword navigate={navigate} />;
        }

        return <Error navigate={navigate} message='Page Not Found' />;
    }

    return (
        <div className="App">
            <NavBar path={path} isLoggedIn={isLoggedIn} />

            {getPageToRender()}

            <Footer />
        </div>
    );
}

export default App;
