import React from 'react';

function NavBar(props) {
    const TABS = [
        {
            title: "Home",
            href: "/",
            requireLogin: false,
        },
        // {
        //     title: "Testimonials",
        //     href: "/testimonials",
        //     requireLogin: false,
        // },
        {
            title: "Contact Us",
            href: "/contact-us",
            requireLogin: false,
        },
        {
            title: "Dashboard",
            href: "/dashboard",
            requireLogin: true,
        },
        {
            title: "Order Form",
            href: "/order-form",
            requireLogin: true,
        },
    ];
    const INACTIVE_CLASS = 'btn btn-outline-primary';
    const ACTIVE_CLASS = 'btn btn-outline-primary active';

    const tabsToShow = props.isLoggedIn ? TABS : TABS.filter(t => !t.requireLogin);

    return (
        <div className="bg-light" style={{padding:'10px', marginBottom:'20px'}}>
            <a href="/" style={{textDecoration:'none'}}>
                <img src="/banner.png" alt="MouthClub banner" width="207" style={{marginRight:'60px'}} />
            </a>

            <div className="btn-group">
                {
                    tabsToShow.map(t =>
                        (<a
                            href={t.href}
                            key={t.title}
                            name="navbar-group"
                            className={t.href===props.path ? ACTIVE_CLASS : INACTIVE_CLASS}
                        >
                            {t.title}
                        </a>)
                    )
                }
            </div>

            {props.isLoggedIn && <a href="/logout" className="btn btn-outline-danger" style={{float:'right'}}>
                Log Out
            </a>}
            {!props.isLoggedIn && <a href="/login" className="btn btn-outline-secondary" style={{float:'right'}}>
                Log In
            </a>}
        </div>
    );
}

export default NavBar;